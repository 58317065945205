<template>
<div class="role-set">
  <div class="title">角色权限设置</div>
  <div class="tips flex-start-start fz14">
    <div style="margin-right: 5px;">提示:</div>
    <div>1、角色代表操作权限，操作权限是指具有该角色的用户是否能查看、新增、编辑、删除某些数据。<br/>
        2、超级管理员的权限是系统默认的最大权限的角色，不能修改。
    </div>
  </div>
  <el-row>
    <el-col :span="5">
      <role-list @setRole="setRole"></role-list>
    </el-col>
    <el-col :span="19">
      <permission :role="roleData"></permission>
    </el-col>
  </el-row>
</div>
</template>
<script>
import roleList from "@/views/Setting/role/roleList";
import permission from "@/views/Setting/role/permission";

export default {
  components: {
    roleList,
    permission
  },
  data() {
    return {
      roleData: {}
    };
  },
  methods: {
    setRole(data) {
      this.roleData = data;
    }
  }
};
</script>

<style lang="less" scoped>
.role-set{
  .title{
    height: 48px;
    line-height: 48px;
    padding-left: 24px;
    border-bottom: 1px solid #DFE9F5;
    margin-bottom: 24px;
  }
  .tips{
    padding:16px;
    background-color: #ecf8ff;
    border-left: 4px solid #78b9ef;
    margin: 20px 20px 10px;
    color:#0f87eb;
    line-height: 22px;
  }
}
</style>
