<template>
  <div class="permission-box" v-loading="loading">
    <div class="permiss-title flex-end">
      <el-button
        v-if="add_role"
        size="mini"
        class="add"
        @click="roleFormShow = true"
      >新增角色</el-button>
    </div>
    <div class="box">
      <div class="item-box" v-for="(item,index) in permissionList" :key="index">
        <div class="title" @click="changeClose(item.close,index)">{{item.auth_name}}</div>
        <div :class="['permission', item.close ? 'active' : '']">
          <div class="auth-item" >
            <div class="checkbox-group">
              <el-checkbox @change="checked=>editRole(checked,child.id)" :style="disebleEidge?'cursor:not-allowed;opacity: 0.6;':''"
                           :disabled="disebleEidge" v-for="(child,childIndex) in item.children"
                           :label="child.id" :key="childIndex" v-model="checkedList[index][childIndex]">{{child.auth_name}}
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog class="role-form-show" title="新增角色" :visible.sync="roleFormShow">
      <el-form :model="form" label-position="left" ref="form" size="mini" label-width="80px">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item slot="footer" class="dialog-footer"></el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="roleFormShow = false">取消</el-button>
        <el-button size="mini" type="primary" @click="saveData(form.name)">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addRole, authLists,editAuth } from "@/api/workbench";
export default {
  props: ["role"], //递交参数
  data() {
    return {
      permission: [],
      disebleEidge: false,
      loading: true,
      roleFormShow: false,
      permissionList: [],
      newAuthList:[],
      permissionList2:[],
      checkedList: [
          [],[],[],[],[]
      ],
      form: {
        id: "",
        name: "",
      },
      checkAll: true,
      isIndeterminate: true,
      create_role: true,
    };
  },
  computed:{
    add_role(){
      return this.$store.state.listData.add_role
    },
  },
  watch: {
    // role(val) {
    //   // this.changeRoleData(val);
    //   this.getPermission(val.role_id);
    // }
    // permissionList: function() {
    //   this.getPermissionShow();
    // }
  },
  created() {
    this.getPermissionShow()
    window.eventBus.$on("setPresm", (item) => {
      this.role_id=item.id;
      this.getPermission(item.id);
    });
  },
  beforeDestroy() {
    window.eventBus.$off("setPresm");
  },
  methods: {
    editRole(checked,id) {
      console.log(checked,id)
      this.newAuthList=[]
      this.permissionList2.map(item=>{
        item.children.map(el=>{
          if(el.id === id){
            el.selected=checked ? 1 : null
          }
        })
      })
      this.permissionList2.map(item=>{
        item.children.map(el=>{
          if(el.selected){
            item.children[0].selected=1;
            item.itemFather=true
          }
        })
      })
      this.permissionList2.map(item=>{
        item.children.map(el=>{
          el.selected!==null ? this.newAuthList.push(el.id) : ''
        })
        item.itemFather ? this.newAuthList.push(item.id) : ''
      })
      // return
      //角色权限编辑
      const _this = this;
      let proms = {
        role_id: _this.role_id,
        auth_id: this.newAuthList.join(','),
      };
      editAuth(proms).then((res) => {
          _this.loading = false;
          if (res.code !== 200) {
            _this.$message.error(res.message);
          }
        })
        .catch((err) => {
          _this.loading = false;
          console.log(err);
        });
    },
    saveData(val) {//新增角色
      if(val.trim()==='' || val.trim()===undefined || val.trim()==null){
        this.$message({
          type:'warning',
          message:"请输入角色名称"
        });
        return
      }
      if(val.length>10){
        this.$message({
          type:'warning',
          message:"角色名称不能大于10个字符"
        });
          return
      }
      if(val.length<2){
        this.$message({
          type:'warning',
          message:"角色名称不能小于2个字符"
        });
        return
      }
      let data = {
        role_name: val,
      };
      addRole(data).then((res) => {
        if (res.code == "200") {
          this.$message({
            type:'success',
            message:res.message
          });
          this.roleFormShow = false;
          this.form = {};
          let val = true;
          window.eventBus.$emit("addRole", val);
        }
      }).catch((err) => {
          console.log(err);
        });
    },
    getPermissionShow() {//权限列表
      return authLists().then((res) => {
        this.permissionList = res.data;
        this.permissionList.forEach((element,index) => {
          index===0 ? element["close"] = false : element["close"] = true;
        });
        this.getPermission(1);
      }).catch(()=>{
        this.loading=false
      })
    },
    changeClose(tag, i) {
      this.permissionList[i].close = !tag;
      this.$forceUpdate()
    },
    getPermission(role_id) {//角色拥有权限
      this.loading=true;
      const that=this;

      role_id === 1 || !this.$store.state.listData.role_auth_set ? this.disebleEidge = true : this.disebleEidge = false;
      if (role_id === "" || role_id === undefined || role_id === null) {
        return false;
      }
      authLists({role_id:role_id}).then(res=> {
        that.checkedList=[]
        res.data.map((item,index)=>{
          that.checkedList.push([])
          item.children.map(el=>{
            if(role_id === 1){
              el.selected=1
            }
            that.checkedList[index].push(el.selected===null ? false :true)
          })
          item.itemFather=false
        })
        that.permissionList2=res.data;
        this.loading = false;
      }).catch(()=>{
        this.loading=false
      })
      that.$forceUpdate()
    },
  },
};
</script>
<style lang="less" scoped>
.permission-box {
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 20px;
  .nav-title {
    padding: 0 11px;
    line-height: 55px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
  }
  h3 {
    font-weight: normal;
    font-size: 14.5px;
  }
  .box {
    padding-top: 10px;
    .item-box {
      .title {
        position: relative;
        height: 42px;
        line-height: 42px;
        padding: 0 15px 0 20px;
        color: #333;
        background-color: #f2f2f2;
        cursor: pointer;
        font-size: 12.5px;
        overflow: hidden;
      }
      margin-bottom: 20px;
      border: 1px solid #ececec;
      border-radius: 2px;
      .permission{
        display: flex;
        &.active {
          height: 0;
          overflow: hidden;
        }
      }
      .auth-item{
        padding:12px 24px;

        .checkbox-group{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
        }
        .el-checkbox{
          display: flex;
          align-items: center;
          width: 220px;
          padding: 12px 0;
        }
      }
    }
  }
  /deep/.el-dialog{
    width: 400px;
    .el-dialog__header {
      padding: 10px 20px;
      .el-dialog__title {
        font-size: 14px;
      }
    }
    .el-dialog__body {
      padding: 10px 30px 0;
      .el-form-item__label {
        font-size: 13px;
        color: #606266;
      }
    }
  }
}
</style>
