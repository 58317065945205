<template>
  <div class="role-box">
    <div class="role-list flex-bet" v-for="(item, index) in roleList" :key="index"
         :class="['item', selectRoleId === index ? 'active' : '']"
         @click="handleSelectRole(item,index)">
      <div class="role-name">
        {{ item.role_name }}
        <span class="default-name fz12">{{item.is_system===1?"(默认)":''}}</span>
      </div>
      <div v-if="item.is_system!==1">
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="edit-more el-dropdown-link"><i class="iconfont icon-gengduo"></i></div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="beforeCom(item,'a')" v-if="edit_role">编辑</el-dropdown-item>
            <el-dropdown-item :command="beforeCom(item,'b')" v-if="del_role">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </div>

    <el-dialog title="编辑" class="role-list-dialog" :visible.sync="roleFormShow">
      <el-form :model="form" ref="form" label-position="top" label-width="150px" size="mini">
        <el-form-item label="角色id：" prop="name" hidden>
          <el-input v-model="form.id" placeholder="请输入角色名字"></el-input>
        </el-form-item>
        <el-form-item label="角色名称：" prop="name">
          <el-input v-model="form.role_name" placeholder="请输入角色名字"></el-input>
        </el-form-item>
        <el-form-item slot="footer" class="dialog-footer"></el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="roleFormShow = false">取消</el-button>
        <el-button size="mini" type="primary" @click="saveData(form)">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { roleList,delRole,editRole } from "@/api/workbench";
export default {
  data() {
    return {
      roleList: [],
      selectRoleId: 0,
      form: {
        id: "",
        role_name: "",
      },
      roleFormShow: false,
      // edit_role: false, //编辑角色
      delete_role: false, //删除角色
    };
  },
  created() {
    this.getRoleData();
    window.eventBus.$on("addRole", (item) => {
      console.log(item);
      this.getRoleData();
    });
  },
  computed:{
    edit_role(){
      return this.$store.state.listData.edit_role
    },
    del_role(){
      return this.$store.state.listData.del_role
    },
  },
  beforeDestroy() {
    window.eventBus.$off("addRole");
  },
  methods: {
    getRoleData() {
      //角色列表
      roleList().then((res) => {
          this.roleList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beforeCom(item,command){//传参
      return {
        'item':item,
        'command':command,
      }
    },
    handleCommand(command){//更多操作
      console.log(command,'xxxxxxx')
      switch (command.command) {
        case "a"://编辑
            this.form.id=command.item.id
            this.form.role_name=command.item.role_name
          this.roleFormShow = true;
          break;
        case "b"://删除
            this.handleDel(command.item.id)
          break;
      }
    },
    handleSelectRole(data, index) {//切换角色
      this.selectRoleId = index;
      this.$emit("setRole", data);
      //全局参数
      window.eventBus.$emit("setPresm", data);
    },
    handleDel(id) {
      //删除角色
      const that=this;
      that.$confirm("是否删除该角色", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delRole({id:id}).then((res) => {
              console.log(res);
              if (res.code == 200) {
                that.getRoleData();
                that.handleSelectRole(this.roleList[0], 0);
                that.$message({
                  message: '删除成功',
                  type: 'success'
                });
              } else {
                this.$message({
                  message: res.message,
                  type: 'warning'
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    saveData(form) {//编辑角色
      console.log(form)
      let val=form.role_name;
      if(val.trim()==='' || val.trim()===undefined || val.trim()==null){
        this.$message("请输入角色名称")
        return
      }
      if(val.length>10){
        this.$message("角色名称不能大于10个字符")
        return
      }
      if(val.length<2){
        this.$message("角色名称不能小于2个字符")
        return
      }
      editRole(form).then((res) => {
            // console.log(res)
            if (res.code === 200) {
              this.form.role_id = "";
              this.form.role_name = "";
              this.roleFormShow = false;
              this.getRoleData();
              this.$message({
                message: res.message,
                type: 'success'
              });
            } else {
              this.$message.error(res.message);
            }
          });
    },
  },
};
</script>
<style lang="less" scoped>
.role-box {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
  width: 182px;
  margin-left: 20px;
  margin-top: 38px;
  min-height: 500px;
  .role-list {
    color: #303233;
    padding-left: 10px;
    .color-info {
      font-size: 10px;
      color: #909399;
    }
    .icon-gengduo{
      color:#C0C6CC;
    }
  }
  .role-list:first-child{
  }
  .item {
    background: #f2f2f2;
    cursor: pointer;
    line-height: 40px;
    min-width: 180px;
    .default-name{
      color:#909399
    }
    &.active {
      color: #333;
      background: #fff;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
      .default-name{
        color:#999;
      }
    }
    &:hover {
      outline: 0;
    }
    i {
      font-size: 16px;
      margin-right: 10px;
    }
  }
  /deep/.el-dialog{
    width: 400px;
    font-size: 14px;
    .el-dialog__header {
      .el-dialog__title {
        font-size: 14px;
      }
    }
    .el-dialog__body {
      padding: 10px 30px 0;
      .el-form-item__label {
        font-size: 13px;
        color: #606266;
      }
    }
  }
}
</style>
